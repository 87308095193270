import Splide from '@splidejs/splide';

export default class HeroSlider {

    getCarouselOptions(container) {

        new Splide(container, {
            type: 'fade',
            autoplay: true,
            interval: 5000,
            speed: 3500,
            easing: 'ease',
            drag: true,
            pagination: false,
            resetProgress: true,
        }).mount();

    }

    triggerCarousel() {

        const blocks = document.querySelectorAll(".hero-slider");
        if (blocks.length) {
            [...blocks].forEach((block) => {
                    const slider = block.querySelector(".splide");
                    this.getCarouselOptions('.' + slider.getAttribute('class'));
                }
            );
        }
    }


    init() {
        this.triggerCarousel();
    }
}
