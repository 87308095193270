export default class Search {

    handleSearchPopup() {
        const searchIcon = document.querySelectorAll('.search-icon');
        const searchPopup = document.querySelector('.search-popup');
        const closeIcon = document.querySelector('.close-search');

        [...searchIcon].forEach((search) => {
            search.addEventListener('click', (e) => {
                searchPopup.classList.add('popup-visible');
            });
        });

        if (closeIcon) {
            closeIcon.addEventListener('click', (e) => {
                searchPopup.classList.remove('popup-visible');
            });
        }

    }

    init() {
        this.handleSearchPopup()
    }
}
