import {tns} from "tiny-slider";

export default class TestimonialsSlider {

    getCarouselOptions(item) {
        return {
            autoplay: true,
            autoWidth: false,
            axis: "horizontal",
            container: item,
            controls: true,
            items: 1,
            nav: false,
            slideBy: 1,
            autoplayButton: false,
            autoplayButtonOutput: false,
            loop: true,
            speed: 700,
            gutter: 25,
            mouseDrag: true,
            controlsContainer: '#testimonials_controls',
            prevButton: '.previous',
            nextButton: '.next',
            touch: true,
        };
    }

    triggerCarousel() {
        const blocks = document.querySelectorAll(".testimonials-slider");
        if (blocks.length) {
            [...blocks].forEach((block) => {
                    const slider = block.querySelector(".testimonials-wrapper");
                    let options = this.getCarouselOptions(slider);
                    let carousel = tns(options);
                }
            );
        }
    }

    init() {
        this.triggerCarousel();
    }
}
