import Ready from "./Helpers/Ready";
import Common from "./Global/Common";
import Menu from "./Global/Menu";
import Search from "./Global/Search";
import LogoSlider from "./Blocks/LogoSlider";
import HeroSlider from "./Blocks/HeroSlider";
import Map from "./Global/Map";
import TestimonialsSlider from "./Blocks/TestimonialsSlider";

Ready(
    () => {

        const common = new Common();
        common.init();

        const menu = new Menu();
        menu.init();

        const search = new Search();
        search.init();

        const logoSlider = new LogoSlider();
        logoSlider.init();

        const heroSlider = new HeroSlider();
        heroSlider.init();

        const testimonialsSlider = new TestimonialsSlider();
        testimonialsSlider.init();

        const map = new Map();
        map.init();

    }
)
