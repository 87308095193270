export default class Map {

    initMap() {
        const mapEl = document.querySelector('#mapCanvas');

        if (mapEl) {
            const lat = parseFloat(mapEl.getAttribute('data-lat'));
            const lng = parseFloat(mapEl.getAttribute('data-lng'));
            const mapType = mapEl.getAttribute('data-type').toUpperCase();
            const zoom = parseFloat(mapEl.getAttribute('data-zoom'));
            const dataTitle = mapEl.getAttribute('data-title');
            let draggable = true;

            if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
                draggable = false;
            }

            let map = new google.maps.Map(mapEl, {
                zoom: zoom,
                scrollwheel: false,
                zoomControl: true,
                draggable: draggable,
                center: new google.maps.LatLng(lat, lng),
                mapTypeId: google.maps.MapTypeId.mapType,
            });

            this.openInfoWindow(map, mapEl, lat, lng, dataTitle);
            this.addColors(map, mapEl);
        }
    }

    openInfoWindow(map, mapEl, lat, lng, title) {
        const image = '/wp-content/themes/AIMS/assets/dist/images/map-marker.png';

        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map,
            icon: image,
            title: title,
            visible: true
        });

        let dataContent = mapEl.getAttribute('data-content');
        let contentString = '';

        if (dataContent !== undefined && dataContent !== false) {
            contentString = '<div class="map-data">' + '<h6>' + title + '</h6>' + '<div class="map-content">' + dataContent + '</div>' + '</div>';
        }
        const infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        if (dataContent !== undefined && dataContent !== false) {
            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }
    }

    addColors(map, mapEl) {
        let dataHue = mapEl.getAttribute('data-hue');

        if (dataHue !== undefined && dataHue !== false) {
            const styles = [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f0ede5"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#8dbaff"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ];
            map.setOptions({styles: styles});

        }
    }

    init() {
        this.initMap();
    }

}
