export default class Menu {

    handleMenuMobile() {
        const menuToggler = document.querySelector(".navbar-toggler");
        const menuHolder = document.querySelector(".navbar-collapse");
        const siteMenuitem = document.querySelector(".site-menu-item");
        const contactPage = document.querySelector(".page-contact");

        if (menuToggler !== null && menuToggler !== "undefined") {
            menuToggler.addEventListener("click", e => {
                e.preventDefault()
                e.stopImmediatePropagation()
                menuToggler.classList.toggle("open")
                if (menuHolder !== null && menuHolder !== "undefined") {
                    menuHolder.classList.toggle("collapse");

                    if (siteMenuitem && menuHolder.classList.contains('collapse')) {
                        menuHolder.style.height = 0;
                    } else {
                        let num = contactPage ? 20 : 0;
                        menuHolder.style.height = siteMenuitem.clientHeight + num + 'px';

                        const dropdownElements = siteMenuitem.querySelectorAll('.site-menu-item > .menu-item');
                        [...dropdownElements].forEach((el) => {

                            const dropdownCTA = el.querySelector('.dropdown-btn');
                            const dropdownMenu = el.querySelector('.dropdown-menu');
                            if (dropdownCTA && dropdownMenu) {
                                dropdownCTA.addEventListener('click', (e) => {
                                    dropdownCTA.classList.toggle("open");

                                    let submenuHeight = 0;
                                    [...dropdownMenu.children].forEach((child) => {
                                        submenuHeight += child.clientHeight;
                                        return submenuHeight;
                                    });

                                    if (submenuHeight !== 0 && dropdownCTA.classList.contains('open')) {
                                        dropdownMenu.style.height = submenuHeight + 'px';
                                        menuHolder.style.height = menuHolder.clientHeight + submenuHeight + 'px';
                                    } else {
                                        dropdownMenu.style.height = 0;
                                        menuHolder.style.height = menuHolder.clientHeight - submenuHeight + 'px';
                                    }
                                });
                            }

                        });

                    }

                }

            });
        }
    }

    init() {
        this.handleMenuMobile()
    }
}
