export default class Common {

    handleScroll() {
        const backToTop = document.getElementById("backtotop");

        if (window.innerWidth >= 992) {
            let pageBody = document.body
            window.addEventListener(
                "scroll",
                function () {
                    (pageBody !== null && pageBody !== "undefined") ? window.scrollY >= 2 ? pageBody.classList.add("scrolled") : pageBody.classList.remove("scrolled") : null

                    if (window.scrollY < 100) {
                        backToTop.classList.add('hidden')
                    } else {
                        backToTop.classList.remove('hidden');
                    }
                }
            );
        }
    }

    backToTop() {
        const backToTop = document.getElementById("backtotop");

        if (backToTop) {

            backToTop.addEventListener('click', () => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            });

        }

    }

    toggleFormElement() {
        const contactPosition = document.querySelectorAll('#contactPosition > span');

        if (contactPosition) {
            const positionOtherInput = document.querySelector('span[data-name="position-other"]');

            if (positionOtherInput) {

                let positionLast = document.querySelector('.wpcf7-list-item.last');
                let positionOtherRadio = positionLast.querySelector('input[name="contact-position"]');

                positionOtherInput.style.display = 'none';

                if (positionOtherRadio.checked) {
                    positionOtherInput.style.display = 'block';
                }

                contactPosition.forEach((radioEl) => {

                    radioEl.addEventListener('click', (e) => {
                        positionOtherRadio = positionLast.querySelector('input[name="contact-position"]');

                        if (positionOtherRadio.checked) {
                            positionOtherInput.style.display = 'block';
                        } else {
                            positionOtherInput.style.display = 'none';
                        }
                    });

                });
            }

        }

    }

    init() {
        this.handleScroll();
        this.backToTop();
        this.toggleFormElement();
    }
}
